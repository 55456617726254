/*!
 * JQVMap: jQuery Vector Map Library
 * @author JQVMap <me@peterschmalfeldt.com>
 * @version 1.5.1
 * @link http://jqvmap.com
 * @license https://github.com/manifestinteractive/jqvmap/blob/master/LICENSE
 * @builddate 2016/06/02
 */

var VectorCanvas = function(width, height, params) {
    this.mode = window.SVGAngle ? 'svg' : 'vml';
    this.params = params;

    if (this.mode === 'svg') {
        this.createSvgNode = function(nodeName) {
            return document.createElementNS(this.svgns, nodeName);
        };
    } else {
        try {
            if (!document.namespaces.rvml) {
                document.namespaces.add('rvml', 'urn:schemas-microsoft-com:vml');
            }
            this.createVmlNode = function(tagName) {
                return document.createElement('<rvml:' + tagName + ' class="rvml">');
            };
        } catch (e) {
            this.createVmlNode = function(tagName) {
                return document.createElement('<' + tagName + ' xmlns="urn:schemas-microsoft.com:vml" class="rvml">');
            };
        }

        document.createStyleSheet().addRule('.rvml', 'behavior:url(#default#VML)');
    }

    if (this.mode === 'svg') {
        this.canvas = this.createSvgNode('svg');
    } else {
        this.canvas = this.createVmlNode('group');
        this.canvas.style.position = 'absolute';
    }

    this.setSize(width, height);
};

VectorCanvas.prototype = {
    svgns: 'http://www.w3.org/2000/svg',
    mode: 'svg',
    width: 0,
    height: 0,
    canvas: null
};

var ColorScale = function(colors, normalizeFunction, minValue, maxValue) {
    if (colors) {
        this.setColors(colors);
    }
    if (normalizeFunction) {
        this.setNormalizeFunction(normalizeFunction);
    }
    if (minValue) {
        this.setMin(minValue);
    }
    if (minValue) {
        this.setMax(maxValue);
    }
};

ColorScale.prototype = {
    colors: []
};

var JQVMap = function(params) {
    params = params || {};
    var map = this;
    var mapData = JQVMap.maps[params.map];
    var mapPins;

    if (!mapData) {
        throw new Error('Invalid "' + params.map + '" map parameter. Please make sure you have loaded this map file in your HTML.');
    }

    this.selectedRegions = [];
    this.multiSelectRegion = params.multiSelectRegion;

    this.container = params.container;

    this.defaultWidth = mapData.width;
    this.defaultHeight = mapData.height;

    this.color = params.color;
    this.selectedColor = params.selectedColor;
    this.hoverColor = params.hoverColor;
    this.hoverColors = params.hoverColors;
    this.hoverOpacity = params.hoverOpacity;
    this.setBackgroundColor(params.backgroundColor);

    this.width = params.container.width();
    this.height = params.container.height();

    this.resize();

    jQuery(window).resize(function() {
        var newWidth = params.container.width();
        var newHeight = params.container.height();

        if (newWidth && newHeight) {
            map.width = newWidth;
            map.height = newHeight;
            map.resize();
            map.canvas.setSize(map.width, map.height);
            map.applyTransform();

            var resizeEvent = jQuery.Event('resize.jqvmap');
            jQuery(params.container).trigger(resizeEvent, [newWidth, newHeight]);

            if (mapPins) {
                jQuery('.jqvmap-pin').remove();
                map.pinHandlers = false;
                map.placePins(mapPins.pins, mapPins.mode);
            }
        }
    });

    this.canvas = new VectorCanvas(this.width, this.height, params);
    params.container.append(this.canvas.canvas);

    this.makeDraggable();

    this.rootGroup = this.canvas.createGroup(true);

    this.index = JQVMap.mapIndex;
    this.label = jQuery('<div/>').addClass('jqvmap-label').appendTo(jQuery('body')).hide();

    if (params.enableZoom) {
        jQuery('<div/>').addClass('jqvmap-zoomin').text('+').appendTo(params.container);
        jQuery('<div/>').addClass('jqvmap-zoomout').html('&#x2212;').appendTo(params.container);
    }

    map.countries = [];

    for (var key in mapData.paths) {
        var path = this.canvas.createPath({
            path: mapData.paths[key].path
        });

        path.setFill(this.color);
        path.id = map.getCountryId(key);
        map.countries[key] = path;

        if (this.canvas.mode === 'svg') {
            path.setAttribute('class', 'jqvmap-region');
        } else {
            jQuery(path).addClass('jqvmap-region');
        }

        jQuery(this.rootGroup).append(path);
    }

    jQuery(params.container).delegate(this.canvas.mode === 'svg' ? 'path' : 'shape', 'mouseover mouseout', function(e) {
        var containerPath = e.target,
            code = e.target.id.split('_').pop(),
            labelShowEvent = jQuery.Event('labelShow.jqvmap'),
            regionMouseOverEvent = jQuery.Event('regionMouseOver.jqvmap');

        code = code.toLowerCase();

        if (e.type === 'mouseover') {
            jQuery(params.container).trigger(regionMouseOverEvent, [code, mapData.paths[code].name]);
            if (!regionMouseOverEvent.isDefaultPrevented()) {
                map.highlight(code, containerPath);
            }
            if (params.showTooltip) {
                map.label.text(mapData.paths[code].name);
                jQuery(params.container).trigger(labelShowEvent, [map.label, code]);

                if (!labelShowEvent.isDefaultPrevented()) {
                    map.label.show();
                    map.labelWidth = map.label.width();
                    map.labelHeight = map.label.height();
                }
            }
        } else {
            map.unhighlight(code, containerPath);

            map.label.hide();
            jQuery(params.container).trigger('regionMouseOut.jqvmap', [code, mapData.paths[code].name]);
        }
    });

    jQuery(params.container).delegate(this.canvas.mode === 'svg' ? 'path' : 'shape', 'click', function(regionClickEvent) {

        var targetPath = regionClickEvent.target;
        var code = regionClickEvent.target.id.split('_').pop();
        var mapClickEvent = jQuery.Event('regionClick.jqvmap');

        code = code.toLowerCase();

        jQuery(params.container).trigger(mapClickEvent, [code, mapData.paths[code].name]);

        if (!params.multiSelectRegion && !mapClickEvent.isDefaultPrevented()) {
            for (var keyPath in mapData.paths) {
                map.countries[keyPath].currentFillColor = map.countries[keyPath].getOriginalFill();
                map.countries[keyPath].setFill(map.countries[keyPath].getOriginalFill());
            }
        }

        if (!mapClickEvent.isDefaultPrevented()) {
            if (map.isSelected(code)) {
                map.deselect(code, targetPath);
            } else {
                map.select(code, targetPath);
            }
        }
    });

    if (params.showTooltip) {
        params.container.mousemove(function(e) {
            if (map.label.is(':visible')) {
                var left = e.pageX - 15 - map.labelWidth;
                var top = e.pageY - 15 - map.labelHeight;

                if (left < 0) {
                    left = e.pageX + 15;
                }
                if (top < 0) {
                    top = e.pageY + 15;
                }

                map.label.css({
                    left: left,
                    top: top
                });
            }
        });
    }

    this.setColors(params.colors);

    this.canvas.canvas.appendChild(this.rootGroup);

    this.applyTransform();

    this.colorScale = new ColorScale(params.scaleColors, params.normalizeFunction, params.valueMin, params.valueMax);

    if (params.values) {
        this.values = params.values;
        this.setValues(params.values);
    }

    if (params.selectedRegions) {
        if (params.selectedRegions instanceof Array) {
            for (var k in params.selectedRegions) {
                this.select(params.selectedRegions[k].toLowerCase());
            }
        } else {
            this.select(params.selectedRegions.toLowerCase());
        }
    }

    this.bindZoomButtons();

    if (params.pins) {
        mapPins = {
            pins: params.pins,
            mode: params.pinMode
        };

        this.pinHandlers = false;
        this.placePins(params.pins, params.pinMode);
    }

    if (params.showLabels) {
        this.pinHandlers = false;

        var pins = {};
        for (key in map.countries) {
            if (typeof map.countries[key] !== 'function') {
                if (!params.pins || !params.pins[key]) {
                    pins[key] = key.toUpperCase();
                }
            }
        }

        mapPins = {
            pins: pins,
            mode: 'content'
        };

        this.placePins(pins, 'content');
    }

    JQVMap.mapIndex++;
};

JQVMap.prototype = {
    transX: 0,
    transY: 0,
    scale: 1,
    baseTransX: 0,
    baseTransY: 0,
    baseScale: 1,
    width: 0,
    height: 0,
    countries: {},
    countriesColors: {},
    countriesData: {},
    zoomStep: 1.4,
    zoomMaxStep: 4,
    zoomCurStep: 1
};

JQVMap.xlink = 'http://www.w3.org/1999/xlink';
JQVMap.mapIndex = 1;
JQVMap.maps = {};

(function() {

    var apiParams = {
        colors: 1,
        values: 1,
        backgroundColor: 1,
        scaleColors: 1,
        normalizeFunction: 1,
        enableZoom: 1,
        showTooltip: 1,
        borderColor: 1,
        borderWidth: 1,
        borderOpacity: 1,
        selectedRegions: 1,
        multiSelectRegion: 1
    };

    var apiEvents = {
        onLabelShow: 'labelShow',
        onLoad: 'load',
        onRegionOver: 'regionMouseOver',
        onRegionOut: 'regionMouseOut',
        onRegionClick: 'regionClick',
        onRegionSelect: 'regionSelect',
        onRegionDeselect: 'regionDeselect',
        onResize: 'resize'
    };

    jQuery.fn.vectorMap = function(options) {

        var defaultParams = {
                map: 'world_en',
                backgroundColor: '#a5bfdd',
                color: '#f4f3f0',
                hoverColor: '#c9dfaf',
                hoverColors: {},
                selectedColor: '#c9dfaf',
                scaleColors: ['#b6d6ff', '#005ace'],
                normalizeFunction: 'linear',
                enableZoom: true,
                showTooltip: true,
                borderColor: '#818181',
                borderWidth: 1,
                borderOpacity: 0.25,
                selectedRegions: null,
                multiSelectRegion: false
            },
            map = this.data('mapObject');

        if (options === 'addMap') {
            JQVMap.maps[arguments[1]] = arguments[2];
        } else if (options === 'set' && apiParams[arguments[1]]) {
            map['set' + arguments[1].charAt(0).toUpperCase() + arguments[1].substr(1)].apply(map, Array.prototype.slice.call(arguments, 2));
        } else if (typeof options === 'string' &&
            typeof map[options] === 'function') {
            return map[options].apply(map, Array.prototype.slice.call(arguments, 1));
        } else {
            jQuery.extend(defaultParams, options);
            defaultParams.container = this;
            this.css({ position: 'relative', overflow: 'hidden' });

            map = new JQVMap(defaultParams);

            this.data('mapObject', map);

            this.unbind('.jqvmap');

            for (var e in apiEvents) {
                if (defaultParams[e]) {
                    this.bind(apiEvents[e] + '.jqvmap', defaultParams[e]);
                }
            }

            var loadEvent = jQuery.Event('load.jqvmap');
            jQuery(defaultParams.container).trigger(loadEvent, map);

            return map;
        }
    };

})(jQuery);

ColorScale.arrayToRgb = function(ar) {
    var rgb = '#';
    var d;
    for (var i = 0; i < ar.length; i++) {
        d = ar[i].toString(16);
        rgb += d.length === 1 ? '0' + d : d;
    }
    return rgb;
};

ColorScale.prototype.getColor = function(value) {
    if (typeof this.normalize === 'function') {
        value = this.normalize(value);
    }

    var lengthes = [];
    var fullLength = 0;
    var l;

    for (var i = 0; i < this.colors.length - 1; i++) {
        l = this.vectorLength(this.vectorSubtract(this.colors[i + 1], this.colors[i]));
        lengthes.push(l);
        fullLength += l;
    }

    var c = (this.maxValue - this.minValue) / fullLength;

    for (i = 0; i < lengthes.length; i++) {
        lengthes[i] *= c;
    }

    i = 0;
    value -= this.minValue;

    while (value - lengthes[i] >= 0) {
        value -= lengthes[i];
        i++;
    }

    var color;
    if (i === this.colors.length - 1) {
        color = this.vectorToNum(this.colors[i]).toString(16);
    } else {
        color = (this.vectorToNum(this.vectorAdd(this.colors[i], this.vectorMult(this.vectorSubtract(this.colors[i + 1], this.colors[i]), (value) / (lengthes[i]))))).toString(16);
    }

    while (color.length < 6) {
        color = '0' + color;
    }
    return '#' + color;
};

ColorScale.rgbToArray = function(rgb) {
    rgb = rgb.substr(1);
    return [parseInt(rgb.substr(0, 2), 16), parseInt(rgb.substr(2, 2), 16), parseInt(rgb.substr(4, 2), 16)];
};

ColorScale.prototype.setColors = function(colors) {
    for (var i = 0; i < colors.length; i++) {
        colors[i] = ColorScale.rgbToArray(colors[i]);
    }
    this.colors = colors;
};

ColorScale.prototype.setMax = function(max) {
    this.clearMaxValue = max;
    if (typeof this.normalize === 'function') {
        this.maxValue = this.normalize(max);
    } else {
        this.maxValue = max;
    }
};

ColorScale.prototype.setMin = function(min) {
    this.clearMinValue = min;

    if (typeof this.normalize === 'function') {
        this.minValue = this.normalize(min);
    } else {
        this.minValue = min;
    }
};

ColorScale.prototype.setNormalizeFunction = function(f) {
    if (f === 'polynomial') {
        this.normalize = function(value) {
            return Math.pow(value, 0.2);
        };
    } else if (f === 'linear') {
        delete this.normalize;
    } else {
        this.normalize = f;
    }
    this.setMin(this.clearMinValue);
    this.setMax(this.clearMaxValue);
};

ColorScale.prototype.vectorAdd = function(vector1, vector2) {
    var vector = [];
    for (var i = 0; i < vector1.length; i++) {
        vector[i] = vector1[i] + vector2[i];
    }
    return vector;
};

ColorScale.prototype.vectorLength = function(vector) {
    var result = 0;
    for (var i = 0; i < vector.length; i++) {
        result += vector[i] * vector[i];
    }
    return Math.sqrt(result);
};

ColorScale.prototype.vectorMult = function(vector, num) {
    var result = [];
    for (var i = 0; i < vector.length; i++) {
        result[i] = vector[i] * num;
    }
    return result;
};

ColorScale.prototype.vectorSubtract = function(vector1, vector2) {
    var vector = [];
    for (var i = 0; i < vector1.length; i++) {
        vector[i] = vector1[i] - vector2[i];
    }
    return vector;
};

ColorScale.prototype.vectorToNum = function(vector) {
    var num = 0;
    for (var i = 0; i < vector.length; i++) {
        num += Math.round(vector[i]) * Math.pow(256, vector.length - i - 1);
    }
    return num;
};

JQVMap.prototype.applyTransform = function() {
    var maxTransX, maxTransY, minTransX, minTransY;
    if (this.defaultWidth * this.scale <= this.width) {
        maxTransX = (this.width - this.defaultWidth * this.scale) / (2 * this.scale);
        minTransX = (this.width - this.defaultWidth * this.scale) / (2 * this.scale);
    } else {
        maxTransX = 0;
        minTransX = (this.width - this.defaultWidth * this.scale) / this.scale;
    }

    if (this.defaultHeight * this.scale <= this.height) {
        maxTransY = (this.height - this.defaultHeight * this.scale) / (2 * this.scale);
        minTransY = (this.height - this.defaultHeight * this.scale) / (2 * this.scale);
    } else {
        maxTransY = 0;
        minTransY = (this.height - this.defaultHeight * this.scale) / this.scale;
    }

    if (this.transY > maxTransY) {
        this.transY = maxTransY;
    } else if (this.transY < minTransY) {
        this.transY = minTransY;
    }
    if (this.transX > maxTransX) {
        this.transX = maxTransX;
    } else if (this.transX < minTransX) {
        this.transX = minTransX;
    }

    this.canvas.applyTransformParams(this.scale, this.transX, this.transY);
};

JQVMap.prototype.bindZoomButtons = function() {
    var map = this;
    this.container.find('.jqvmap-zoomin').click(function() {
        map.zoomIn();
    });
    this.container.find('.jqvmap-zoomout').click(function() {
        map.zoomOut();
    });
};

JQVMap.prototype.deselect = function(cc, path) {
    cc = cc.toLowerCase();
    path = path || jQuery('#' + this.getCountryId(cc))[0];

    if (this.isSelected(cc)) {
        this.selectedRegions.splice(this.selectIndex(cc), 1);

        jQuery(this.container).trigger('regionDeselect.jqvmap', [cc]);
        path.currentFillColor = path.getOriginalFill();
        path.setFill(path.getOriginalFill());
    } else {
        for (var key in this.countries) {
            this.selectedRegions.splice(this.selectedRegions.indexOf(key), 1);
            this.countries[key].currentFillColor = this.color;
            this.countries[key].setFill(this.color);
        }
    }
};

JQVMap.prototype.getCountryId = function(cc) {
    return 'jqvmap' + this.index + '_' + cc;
};

JQVMap.prototype.getPin = function(cc) {
    var pinObj = jQuery('#' + this.getPinId(cc));
    return pinObj.html();
};

JQVMap.prototype.getPinId = function(cc) {
    return this.getCountryId(cc) + '_pin';
};

JQVMap.prototype.getPins = function() {
    var pins = this.container.find('.jqvmap-pin');
    var ret = {};
    jQuery.each(pins, function(index, pinObj) {
        pinObj = jQuery(pinObj);
        var cc = pinObj.attr('for').toLowerCase();
        var pinContent = pinObj.html();
        ret[cc] = pinContent;
    });
    return JSON.stringify(ret);
};

JQVMap.prototype.highlight = function(cc, path) {
    path = path || jQuery('#' + this.getCountryId(cc))[0];
    if (this.hoverOpacity) {
        path.setOpacity(this.hoverOpacity);
    } else if (this.hoverColors && (cc in this.hoverColors)) {
        path.currentFillColor = path.getFill() + '';
        path.setFill(this.hoverColors[cc]);
    } else if (this.hoverColor) {
        path.currentFillColor = path.getFill() + '';
        path.setFill(this.hoverColor);
    }
};

JQVMap.prototype.isSelected = function(cc) {
    return this.selectIndex(cc) >= 0;
};

JQVMap.prototype.makeDraggable = function() {
    var mouseDown = false;
    var oldPageX, oldPageY;
    var self = this;

    self.isMoving = false;
    self.isMovingTimeout = false;

    var lastTouchCount;
    var touchCenterX;
    var touchCenterY;
    var touchStartDistance;
    var touchStartScale;
    var touchX;
    var touchY;

    this.container.mousemove(function(e) {

        if (mouseDown) {
            self.transX -= (oldPageX - e.pageX) / self.scale;
            self.transY -= (oldPageY - e.pageY) / self.scale;

            self.applyTransform();

            oldPageX = e.pageX;
            oldPageY = e.pageY;

            self.isMoving = true;
            if (self.isMovingTimeout) {
                clearTimeout(self.isMovingTimeout);
            }

            self.container.trigger('drag');
        }

        return false;

    }).mousedown(function(e) {

        mouseDown = true;
        oldPageX = e.pageX;
        oldPageY = e.pageY;

        return false;

    }).mouseup(function() {

        mouseDown = false;

        clearTimeout(self.isMovingTimeout);
        self.isMovingTimeout = setTimeout(function() {
            self.isMoving = false;
        }, 100);

        return false;

    }).mouseout(function() {

        if (mouseDown && self.isMoving) {

            clearTimeout(self.isMovingTimeout);
            self.isMovingTimeout = setTimeout(function() {
                mouseDown = false;
                self.isMoving = false;
            }, 100);

            return false;
        }
    });

    jQuery(this.container).bind('touchmove', function(e) {

        var offset;
        var scale;
        var touches = e.originalEvent.touches;
        var transformXOld;
        var transformYOld;

        if (touches.length === 1) {
            if (lastTouchCount === 1) {

                if (touchX === touches[0].pageX && touchY === touches[0].pageY) {
                    return;
                }

                transformXOld = self.transX;
                transformYOld = self.transY;

                self.transX -= (touchX - touches[0].pageX) / self.scale;
                self.transY -= (touchY - touches[0].pageY) / self.scale;

                self.applyTransform();

                if (transformXOld !== self.transX || transformYOld !== self.transY) {
                    e.preventDefault();
                }

                self.isMoving = true;
                if (self.isMovingTimeout) {
                    clearTimeout(self.isMovingTimeout);
                }
            }

            touchX = touches[0].pageX;
            touchY = touches[0].pageY;

        } else if (touches.length === 2) {

            if (lastTouchCount === 2) {
                scale = Math.sqrt(
                    Math.pow(touches[0].pageX - touches[1].pageX, 2) +
                    Math.pow(touches[0].pageY - touches[1].pageY, 2)
                ) / touchStartDistance;

                self.setScale(
                    touchStartScale * scale,
                    touchCenterX,
                    touchCenterY
                );

                e.preventDefault();

            } else {

                offset = jQuery(self.container).offset();
                if (touches[0].pageX > touches[1].pageX) {
                    touchCenterX = touches[1].pageX + (touches[0].pageX - touches[1].pageX) / 2;
                } else {
                    touchCenterX = touches[0].pageX + (touches[1].pageX - touches[0].pageX) / 2;
                }

                if (touches[0].pageY > touches[1].pageY) {
                    touchCenterY = touches[1].pageY + (touches[0].pageY - touches[1].pageY) / 2;
                } else {
                    touchCenterY = touches[0].pageY + (touches[1].pageY - touches[0].pageY) / 2;
                }

                touchCenterX -= offset.left;
                touchCenterY -= offset.top;
                touchStartScale = self.scale;

                touchStartDistance = Math.sqrt(
                    Math.pow(touches[0].pageX - touches[1].pageX, 2) +
                    Math.pow(touches[0].pageY - touches[1].pageY, 2)
                );
            }
        }

        lastTouchCount = touches.length;
    });

    jQuery(this.container).bind('touchstart', function() {
        lastTouchCount = 0;
    });

    jQuery(this.container).bind('touchend', function() {
        lastTouchCount = 0;
    });
};

JQVMap.prototype.placePins = function(pins, pinMode) {
    var map = this;

    if (!pinMode || (pinMode !== 'content' && pinMode !== 'id')) {
        pinMode = 'content';
    }

    if (pinMode === 'content') { //treat pin as content
        jQuery.each(pins, function(index, pin) {
            if (jQuery('#' + map.getCountryId(index)).length === 0) {
                return;
            }

            var pinIndex = map.getPinId(index);
            var $pin = jQuery('#' + pinIndex);
            if ($pin.length > 0) {
                $pin.remove();
            }
            map.container.append('<div id="' + pinIndex + '" for="' + index + '" class="jqvmap-pin" style="position:absolute">' + pin + '</div>');
        });
    } else { //treat pin as id of an html content
        jQuery.each(pins, function(index, pin) {
            if (jQuery('#' + map.getCountryId(index)).length === 0) {
                return;
            }
            var pinIndex = map.getPinId(index);
            var $pin = jQuery('#' + pinIndex);
            if ($pin.length > 0) {
                $pin.remove();
            }
            map.container.append('<div id="' + pinIndex + '" for="' + index + '" class="jqvmap-pin" style="position:absolute"></div>');
            $pin.append(jQuery('#' + pin));
        });
    }

    this.positionPins();
    if (!this.pinHandlers) {
        this.pinHandlers = true;
        var positionFix = function() {
            map.positionPins();
        };
        this.container.bind('zoomIn', positionFix)
            .bind('zoomOut', positionFix)
            .bind('drag', positionFix);
    }
};

JQVMap.prototype.positionPins = function() {
    var map = this;
    var pins = this.container.find('.jqvmap-pin');
    jQuery.each(pins, function(index, pinObj) {
        pinObj = jQuery(pinObj);
        var countryId = map.getCountryId(pinObj.attr('for').toLowerCase());
        var countryObj = jQuery('#' + countryId);
        var bbox = countryObj[0].getBBox();

        var scale = map.scale;
        var rootCoords = map.canvas.rootGroup.getBoundingClientRect();
        var mapCoords = map.container[0].getBoundingClientRect();
        var coords = {
            left: rootCoords.left - mapCoords.left,
            top: rootCoords.top - mapCoords.top
        };

        var middleX = (bbox.x * scale) + ((bbox.width * scale) / 2);
        var middleY = (bbox.y * scale) + ((bbox.height * scale) / 2);

        pinObj.css({
            left: coords.left + middleX - (pinObj.width() / 2),
            top: coords.top + middleY - (pinObj.height() / 2)
        });
    });
};

JQVMap.prototype.removePin = function(cc) {
    cc = cc.toLowerCase();
    jQuery('#' + this.getPinId(cc)).remove();
};

JQVMap.prototype.removePins = function() {
    this.container.find('.jqvmap-pin').remove();
};

JQVMap.prototype.reset = function() {
    for (var key in this.countries) {
        this.countries[key].setFill(this.color);
    }
    this.scale = this.baseScale;
    this.transX = this.baseTransX;
    this.transY = this.baseTransY;
    this.applyTransform();
    this.zoomCurStep = 1;
};

JQVMap.prototype.resize = function() {
    var curBaseScale = this.baseScale;
    if (this.width / this.height > this.defaultWidth / this.defaultHeight) {
        this.baseScale = this.height / this.defaultHeight;
        this.baseTransX = Math.abs(this.width - this.defaultWidth * this.baseScale) / (2 * this.baseScale);
    } else {
        this.baseScale = this.width / this.defaultWidth;
        this.baseTransY = Math.abs(this.height - this.defaultHeight * this.baseScale) / (2 * this.baseScale);
    }
    this.scale *= this.baseScale / curBaseScale;
    this.transX *= this.baseScale / curBaseScale;
    this.transY *= this.baseScale / curBaseScale;
};

JQVMap.prototype.select = function(cc, path) {
    cc = cc.toLowerCase();
    path = path || jQuery('#' + this.getCountryId(cc))[0];

    if (!this.isSelected(cc)) {
        if (this.multiSelectRegion) {
            this.selectedRegions.push(cc);
        } else {
            this.selectedRegions = [cc];
        }

        jQuery(this.container).trigger('regionSelect.jqvmap', [cc]);
        if (this.selectedColor && path) {
            path.currentFillColor = this.selectedColor;
            path.setFill(this.selectedColor);
        }
    }
};

JQVMap.prototype.selectIndex = function(cc) {
    cc = cc.toLowerCase();
    for (var i = 0; i < this.selectedRegions.length; i++) {
        if (cc === this.selectedRegions[i]) {
            return i;
        }
    }
    return -1;
};

JQVMap.prototype.setBackgroundColor = function(backgroundColor) {
    this.container.css('background-color', backgroundColor);
};

JQVMap.prototype.setColors = function(key, color) {
    if (typeof key === 'string') {
        this.countries[key].setFill(color);
        this.countries[key].setAttribute('original', color);
    } else {
        var colors = key;

        for (var code in colors) {
            if (this.countries[code]) {
                this.countries[code].setFill(colors[code]);
                this.countries[code].setAttribute('original', colors[code]);
            }
        }
    }
};

JQVMap.prototype.setNormalizeFunction = function(f) {
    this.colorScale.setNormalizeFunction(f);

    if (this.values) {
        this.setValues(this.values);
    }
};

JQVMap.prototype.setScale = function(scale) {
    this.scale = scale;
    this.applyTransform();
};

JQVMap.prototype.setScaleColors = function(colors) {
    this.colorScale.setColors(colors);

    if (this.values) {
        this.setValues(this.values);
    }
};

JQVMap.prototype.setValues = function(values) {
    var max = 0,
        min = Number.MAX_VALUE,
        val;

    for (var cc in values) {
        cc = cc.toLowerCase();
        val = parseFloat(values[cc]);

        if (isNaN(val)) {
            continue;
        }
        if (val > max) {
            max = values[cc];
        }
        if (val < min) {
            min = val;
        }
    }

    if (min === max) {
        max++;
    }

    this.colorScale.setMin(min);
    this.colorScale.setMax(max);

    var colors = {};
    for (cc in values) {
        cc = cc.toLowerCase();
        val = parseFloat(values[cc]);
        colors[cc] = isNaN(val) ? this.color : this.colorScale.getColor(val);
    }
    this.setColors(colors);
    this.values = values;
};

JQVMap.prototype.unhighlight = function(cc, path) {
    cc = cc.toLowerCase();
    path = path || jQuery('#' + this.getCountryId(cc))[0];
    path.setOpacity(1);
    if (path.currentFillColor) {
        path.setFill(path.currentFillColor);
    }
};

JQVMap.prototype.zoomIn = function() {
    var map = this;
    var sliderDelta = (jQuery('#zoom').innerHeight() - 6 * 2 - 15 * 2 - 3 * 2 - 7 - 6) / (this.zoomMaxStep - this.zoomCurStep);

    if (map.zoomCurStep < map.zoomMaxStep) {
        map.transX -= (map.width / map.scale - map.width / (map.scale * map.zoomStep)) / 2;
        map.transY -= (map.height / map.scale - map.height / (map.scale * map.zoomStep)) / 2;
        map.setScale(map.scale * map.zoomStep);
        map.zoomCurStep++;

        var $slider = jQuery('#zoomSlider');

        $slider.css('top', parseInt($slider.css('top'), 10) - sliderDelta);

        map.container.trigger('zoomIn');
    }
};

JQVMap.prototype.zoomOut = function() {
    var map = this;
    var sliderDelta = (jQuery('#zoom').innerHeight() - 6 * 2 - 15 * 2 - 3 * 2 - 7 - 6) / (this.zoomMaxStep - this.zoomCurStep);

    if (map.zoomCurStep > 1) {
        map.transX += (map.width / (map.scale / map.zoomStep) - map.width / map.scale) / 2;
        map.transY += (map.height / (map.scale / map.zoomStep) - map.height / map.scale) / 2;
        map.setScale(map.scale / map.zoomStep);
        map.zoomCurStep--;

        var $slider = jQuery('#zoomSlider');

        $slider.css('top', parseInt($slider.css('top'), 10) + sliderDelta);

        map.container.trigger('zoomOut');
    }
};

VectorCanvas.prototype.applyTransformParams = function(scale, transX, transY) {
    if (this.mode === 'svg') {
        this.rootGroup.setAttribute('transform', 'scale(' + scale + ') translate(' + transX + ', ' + transY + ')');
    } else {
        this.rootGroup.coordorigin = (this.width - transX) + ',' + (this.height - transY);
        this.rootGroup.coordsize = this.width / scale + ',' + this.height / scale;
    }
};

VectorCanvas.prototype.createGroup = function(isRoot) {
    var node;
    if (this.mode === 'svg') {
        node = this.createSvgNode('g');
    } else {
        node = this.createVmlNode('group');
        node.style.width = this.width + 'px';
        node.style.height = this.height + 'px';
        node.style.left = '0px';
        node.style.top = '0px';
        node.coordorigin = '0 0';
        node.coordsize = this.width + ' ' + this.height;
    }

    if (isRoot) {
        this.rootGroup = node;
    }
    return node;
};

VectorCanvas.prototype.createPath = function(config) {
    var node;
    if (this.mode === 'svg') {
        node = this.createSvgNode('path');
        node.setAttribute('d', config.path);

        if (this.params.borderColor !== null) {
            node.setAttribute('stroke', this.params.borderColor);
        }
        if (this.params.borderWidth > 0) {
            node.setAttribute('stroke-width', this.params.borderWidth);
            node.setAttribute('stroke-linecap', 'round');
            node.setAttribute('stroke-linejoin', 'round');
        }
        if (this.params.borderOpacity > 0) {
            node.setAttribute('stroke-opacity', this.params.borderOpacity);
        }

        node.setFill = function(color) {
            this.setAttribute('fill', color);
            if (this.getAttribute('original') === null) {
                this.setAttribute('original', color);
            }
        };

        node.getFill = function() {
            return this.getAttribute('fill');
        };

        node.getOriginalFill = function() {
            return this.getAttribute('original');
        };

        node.setOpacity = function(opacity) {
            this.setAttribute('fill-opacity', opacity);
        };
    } else {
        node = this.createVmlNode('shape');
        node.coordorigin = '0 0';
        node.coordsize = this.width + ' ' + this.height;
        node.style.width = this.width + 'px';
        node.style.height = this.height + 'px';
        node.fillcolor = JQVMap.defaultFillColor;
        node.stroked = false;
        node.path = VectorCanvas.pathSvgToVml(config.path);

        var scale = this.createVmlNode('skew');
        scale.on = true;
        scale.matrix = '0.01,0,0,0.01,0,0';
        scale.offset = '0,0';

        node.appendChild(scale);

        var fill = this.createVmlNode('fill');
        node.appendChild(fill);

        node.setFill = function(color) {
            this.getElementsByTagName('fill')[0].color = color;
            if (this.getAttribute('original') === null) {
                this.setAttribute('original', color);
            }
        };

        node.getFill = function() {
            return this.getElementsByTagName('fill')[0].color;
        };
        node.getOriginalFill = function() {
            return this.getAttribute('original');
        };
        node.setOpacity = function(opacity) {
            this.getElementsByTagName('fill')[0].opacity = parseInt(opacity * 100, 10) + '%';
        };
    }
    return node;
};

VectorCanvas.prototype.pathSvgToVml = function(path) {
    var result = '';
    var cx = 0,
        cy = 0,
        ctrlx, ctrly;

    return path.replace(/([MmLlHhVvCcSs])((?:-?(?:\d+)?(?:\.\d+)?,?\s?)+)/g, function(segment, letter, coords) {
        coords = coords.replace(/(\d)-/g, '$1,-').replace(/\s+/g, ',').split(',');
        if (!coords[0]) {
            coords.shift();
        }

        for (var i = 0, l = coords.length; i < l; i++) {
            coords[i] = Math.round(100 * coords[i]);
        }

        switch (letter) {
            case 'm':
                cx += coords[0];
                cy += coords[1];
                result = 't' + coords.join(',');
                break;

            case 'M':
                cx = coords[0];
                cy = coords[1];
                result = 'm' + coords.join(',');
                break;

            case 'l':
                cx += coords[0];
                cy += coords[1];
                result = 'r' + coords.join(',');
                break;

            case 'L':
                cx = coords[0];
                cy = coords[1];
                result = 'l' + coords.join(',');
                break;

            case 'h':
                cx += coords[0];
                result = 'r' + coords[0] + ',0';
                break;

            case 'H':
                cx = coords[0];
                result = 'l' + cx + ',' + cy;
                break;

            case 'v':
                cy += coords[0];
                result = 'r0,' + coords[0];
                break;

            case 'V':
                cy = coords[0];
                result = 'l' + cx + ',' + cy;
                break;

            case 'c':
                ctrlx = cx + coords[coords.length - 4];
                ctrly = cy + coords[coords.length - 3];
                cx += coords[coords.length - 2];
                cy += coords[coords.length - 1];
                result = 'v' + coords.join(',');
                break;

            case 'C':
                ctrlx = coords[coords.length - 4];
                ctrly = coords[coords.length - 3];
                cx = coords[coords.length - 2];
                cy = coords[coords.length - 1];
                result = 'c' + coords.join(',');
                break;

            case 's':
                coords.unshift(cy - ctrly);
                coords.unshift(cx - ctrlx);
                ctrlx = cx + coords[coords.length - 4];
                ctrly = cy + coords[coords.length - 3];
                cx += coords[coords.length - 2];
                cy += coords[coords.length - 1];
                result = 'v' + coords.join(',');
                break;

            case 'S':
                coords.unshift(cy + cy - ctrly);
                coords.unshift(cx + cx - ctrlx);
                ctrlx = coords[coords.length - 4];
                ctrly = coords[coords.length - 3];
                cx = coords[coords.length - 2];
                cy = coords[coords.length - 1];
                result = 'c' + coords.join(',');
                break;

            default:
                break;
        }

        return result;

    }).replace(/z/g, '');
};

VectorCanvas.prototype.setSize = function(width, height) {
    if (this.mode === 'svg') {
        this.canvas.setAttribute('width', width);
        this.canvas.setAttribute('height', height);
    } else {
        this.canvas.style.width = width + 'px';
        this.canvas.style.height = height + 'px';
        this.canvas.coordsize = width + ' ' + height;
        this.canvas.coordorigin = '0 0';
        if (this.rootGroup) {
            var paths = this.rootGroup.getElementsByTagName('shape');
            for (var i = 0, l = paths.length; i < l; i++) {
                paths[i].coordsize = width + ' ' + height;
                paths[i].style.width = width + 'px';
                paths[i].style.height = height + 'px';
            }
            this.rootGroup.coordsize = width + ' ' + height;
            this.rootGroup.style.width = width + 'px';
            this.rootGroup.style.height = height + 'px';
        }
    }
    this.width = width;
    this.height = height;
};