var mapController = require("./mapPresenter.js");

var langImages = {
    gl: "assets/images/gl.png",
    es: "assets/images/es.png"
};

var langTexts = {
    gl: "Galego",
    es: "Castellano"
};

// Enable debug
$.i18n.debug = true;

var currentLocale = sessionStorage.getItem("currentLocale");

function set_locale_to(locale) {
    if (locale) $.i18n().locale = locale;
}

jQuery(function updateLocale($) {
    "use strict";
    var i18n = $.i18n();

    i18n.locale = currentLocale;

    i18n
        .load({
            es: "./src/i18n/map_es.json",
            gl: "./src/i18n/map_gl.json"
        })
        .done(function() {
            console.log("Internationalization files loaded correctly");

            $("#titleimage").attr(
                "src", langImages[i18n.locale]);

            $("#titletext").text(langTexts[i18n.locale]);

            History.Adapter.bind(window, "statechange", function() {
                set_locale_to(url("?locale"));
            });

            History.pushState(null, null, "?locale=" + i18n.locale);
            mapController.updatePageElements();
            mapController.showLevelUnlocked();

            $(".switch-locale").on("click", "a", function(e) {
                console.log("locale changed");
                e.preventDefault();
                History.pushState(null, null, "?locale=" + $(this).data("locale"));
                $.i18n().locale = $(this).data("locale");
                mapController.updatePageElements();
            });

            $(".languages > li").click(function() {
                $("#titleimage").attr(
                    "src",
                    $(this)
                    .children()
                    .children("img")
                    .attr("src")
                );
                $("#titletext").text($(this).text());
            });
        });
});