function loadTextFileAjaxSync(file) {
  var xobj = new XMLHttpRequest();
  xobj.overrideMimeType("application/json");
  xobj.open("GET", file, false); // Replace file with the path to your file
  xobj.send();
  if (xobj.status == 200) {
    return xobj.responseText;
  } else {
    // TODO Throw exception
    return null;
  }
}

module.exports.loadJsonFile = function loadJson(filePath) {
  // Load json file;
  var json = loadTextFileAjaxSync(filePath);
  // Parse json
  return JSON.parse(json);
};

