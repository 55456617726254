var jsonLoader = require("./jsonLoader.js");

var globalConfig = jsonLoader.loadJsonFile("conf/global_config.json");

var levelsUnlocked = [globalConfig.first_level_unlocked];

var colors = {};

var globalLevels = globalConfig.levels;

var levelUnlocked;

var firstLevelCode = globalConfig.first_level_unlocked.code.replace(/["]+/g, '');

var levelFiles = {};

var _init = function () {
    if (sessionStorage["levelsUnlocked"] == null) {
        sessionStorage.setItem("levelsUnlocked", JSON.stringify(levelsUnlocked));
    } else {
        levelsUnlocked = JSON.parse(sessionStorage.getItem("levelsUnlocked"));
    }

    levelFiles[firstLevelCode] = globalConfig.first_level_file;

    if (sessionStorage["levelFiles"] == null) {
        sessionStorage.setItem("levelFiles", JSON.stringify(levelFiles));
    } else {
        levelFiles = JSON.parse(sessionStorage.getItem("levelFiles"));
    }

    if (sessionStorage["currentLocale"] == null) {
        sessionStorage.setItem("currentLocale", "gl");
    }

    for (var level in globalLevels) {
        levelUnlocked = false;
        for (var unlocked in levelsUnlocked) {
            if (levelsUnlocked[unlocked].code == globalLevels[level]) {
                colors[globalLevels[level]] = globalConfig.unlocked_level_color;
                levelUnlocked = true;
                break;
            }
        }
        if (!levelUnlocked) {
            colors[globalLevels[level]] = globalConfig.locked_level_color;
        }
    }

    if (sessionStorage["colors"] == null) {
        sessionStorage.setItem("colors", JSON.stringify(colors));
    } else {
        colors = JSON.parse(sessionStorage.getItem("colors"));
    }

}

function getLevelText(level) {
    var levelText = $.i18n("description_level_".concat(level));
    var toret = "";
    var arr = levelText.split("~");
    for (index = 0; index < arr.length; ++index) {
        var str = arr[index].replace(/^/, "<li>").concat("</li>");
        toret += str;
    }
    toret.replace(/^/, "<ul>").concat("</ul>");
    return toret;
}

function showLevelDescription(currentLevel, levelText) {
    swal({
        title: "Nivel " + currentLevel + ": " + $.i18n("level_".concat(currentLevel)),
        html: levelText,
        allowOutsideClick: false,
        allowEscapeKey: false,
        timer: 6000,

        onOpen: function () {
            var b = swal.getConfirmButton();
            b.hidden = true;
            b.disabled = true;
        }
    }).then(function () {
        swal({
            title: "Nivel " + currentLevel + ": " + $.i18n("level_".concat(currentLevel)),
            html: levelText,
            allowOutsideClick: true,
            allowEscapeKey: true,
            showCloseButton: true,
            showCancelButton: true,
            animation: false
        }).then((result) => {
            if (result.value) {
                if (sessionStorage) {
                    sessionStorage.setItem("currentLocale", $.i18n().locale);
                }
                location.replace("game.html");
            } else {
                result.dismiss === swal.DismissReason.cancel;
            }
        });
    });
}

_init();

$(document).ready(function () {
    var selectedRegions = [];

    for (var level in levelsUnlocked) {
        selectedRegions.push(levelsUnlocked[level].code);
    }

    jQuery("#vmap").vectorMap({
        map: "world_en",
        backgroundColor: "#a5bfdd",
        borderColor: "#818181",
        borderOpacity: 0.5,
        borderWidth: 1,
        colors: colors,
        enableZoom: true,
        hoverColor: "#33ff33",
        hoverOpacity: null,
        normalizeFunction: "linear",
        scaleColors: ["#b6d6ff", "#005ace"],
        selectedColor: "#00cc00",
        selectedRegions: selectedRegions,
        showTooltip: true,
        onRegionClick: function (element, code, region) {
            if (!selectedRegions.includes(code)) {
                event.preventDefault();
            } else {
                // if (supports_html5_storage()) {
                //     if (code == 'es') {
                //         localStorage.setItem("levelFile", "1.json");
                //     } else if (code == 'hn') {
                //         localStorage.setItem("levelFile", "2.json");
                //     }
                // }
                if (sessionStorage) {
                    sessionStorage.setItem("levelFile", levelFiles[code]);
                    var currentLevel = levelFiles[code].split(".")[0];
                    var levelText = getLevelText(currentLevel);
                    showLevelDescription(currentLevel, levelText);
                } else {
                    alert("Sorry, your browser do not support session storage.");
                }

            }
        },

        onLoad: function (event, map) { },
        onLabelShow: function (event, label, code) { },
        onRegionOver: function (event, code, region) {
            if (!selectedRegions.includes(code)) {
                event.preventDefault();
            }
        },
        onRegionOut: function (event, code, region) { },

        onResize: function (event, width, height) { }
    });
    module.exports.updatePageElements = function updatePageElements() {
        $("#tetris-header").text($.i18n("tetris_header"));
    };

    module.exports.showLevelUnlocked = function showLevelUnlocked() {
        for (var level in levelsUnlocked) {
            if (!levelsUnlocked[level].shown) {
                levelsUnlocked[level].shown = true;
                sessionStorage.setItem("levelsUnlocked", JSON.stringify(levelsUnlocked));
                swal({
                    title: $.i18n("level_unlocked_title"),
                    html: $.i18n("level_unlocked_text").concat($.i18n(levelsUnlocked[level].code)).concat("."),
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    showCloseButton: true,
                    animation: true
                }).then((result) => { });
            }
        }
    }
});

function supports_html5_storage() {
    try {
        return "localStorage" in window && window["localStorage"] !== null;
    } catch (e) {
        return false;
    }
}