//Map JS code
var mapPresenter = require('./src/mapPresenter.js');

var jvmap = require('./src/map/jquery.vmap.js');

var worldMap = require('./src/map/maps/jquery.vmap.world.js');

//Internationalizations libs
var cldrp = require('./src/lib/CLDRPluralRuleParser/CLDRPluralRuleParser.js');

var jqueryi18n = require('./src/lib/jquery.i18n/jquery.i18n.js');

var messagestore = require('./src/lib/jquery.i18n/jquery.i18n.messagestore.js');

var fallbacks = require('./src/lib/jquery.i18n/jquery.i18n.fallbacks.js');

var language = require('./src/lib/jquery.i18n/jquery.i18n.language.js');

var parser = require('./src/lib/jquery.i18n/jquery.i18n.parser.js');

var emitter = require('./src/lib/jquery.i18n/jquery.i18n.emitter.js');

var emitterbidi = require('./src/lib/jquery.i18n/jquery.i18n.emitter.bidi.js');

var history = require('./src/lib/history/jquery.history.js');

var url = require('./src/lib/url/url.min.js');

var mapLanguagePicker = require('./src/mapLanguagePicker.js');

var jsonLoader = require('./src/jsonLoader.js');